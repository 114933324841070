import { NavLink } from "react-router-dom";
import headerLogo from "../images/logo.png";
import { Navbar, Nav, Offcanvas } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { ViewData } from "../Services/AxiosService";
import { ConfigServices } from "../Services/Config";
function Header() {
  const [showMenu, setShowMenu] = useState(false);
  const [showup, setshowup] = useState(false);
  useEffect(() => {
    ViewData(ConfigServices.BASE_PATH_UPEVENTS + "view/")
      .then((res) => {
        if(res.length > 0){
          setshowup(true)
        }
      })
      .catch((err) => {});
  },[]);
  const handleClose = () => setShowMenu(false);
  const handleShow = () => setShowMenu(true);
  return (
    <>
      <header class="header_four">
        <div class="header_top">
          <div class="container">
            <div class="row">
              <div class="col-12 col-sm-12 col-lg-12">
                <div class="info_wrapper">
                  <div class="contact_info">
                    <ul class="list-unstyled">
                      <li>
                        <i class="flaticon-phone-receiver"></i>+91 - 84205 55755
                      </li>
                      <li>
                        <i class="flaticon-mail-black-envelope-symbol"></i>
                        contact@demmistotechnologies.com
                      </li>
                    </ul>
                  </div>
                  <div class="login_info">
                    <NavLink to="register" className="apply_btn">
                      Apply Now
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="edu_nav">
          {["md"].map((expand) => (
            <Container>
              <Navbar
                key={expand}
                expand={expand}
                className="navbar navbar-expand-md navbar-light bg-faded"
              >
                <NavLink to="" className="navbar-brand">
                  <img src={headerLogo} alt="logo" />
                </NavLink>
                <Navbar.Toggle onClick={handleShow} />
                <Navbar.Collapse>
                  <ul class="navbar-nav nav lavalamp ml-auto menu">
                    <li class="nav-item">
                      <NavLink to="" className="nav-link">
                        Home
                      </NavLink>
                    </li>
                    <li class="nav-item">
                      <NavLink to="about" className="nav-link">
                        About Us
                      </NavLink>
                      <ul class="navbar-nav nav" style={{width:'240px'}}>
                        <li class="nav-item">
                      <NavLink to="team" className="nav-link">
                      Management team
                      </NavLink>
                    </li>
                    </ul>
                    </li>
                    <li class="nav-item">
                      <a href="#" className="nav-link">
                        Training
                      </a>
                      <ul class="navbar-nav nav mx-auto training_menu">
                        <li class="nav-item">
                          <b>Ethical Hacking</b>
                          <NavLink
                            to="training/certified-ethical-hacking-course"
                            className="nav-link"
                          >
                            Certified Information Security and Ethical Hacker
                            (CISEH)
                          </NavLink>
                        </li>
                        <li class="nav-item">
                          <b>Pentesting</b>
                          <NavLink
                            to="training/certified-penetration-testing-expert-course"
                            className="nav-link"
                          >
                            Certified Penetration Testing Expert (CPTE)
                          </NavLink>
                        </li>
                        <li class="nav-item">
                          <b>Cyber Crime Investigation</b>
                          <NavLink
                            to="training/certified-cyber-crime-investigator-course"
                            className="nav-link"
                          >
                            Certified Cyber Crime Expert and Investigator(CCEI)
                          </NavLink>
                        </li>
                        <li class="nav-item">
                          <b>SOC/Blue Team</b>
                          <NavLink
                            to="training/certified-soc-analyst-training"
                            className="nav-link"
                          >
                            Certified SOC Engineer (CSE)
                          </NavLink>
                        </li>
                        <li class="nav-item">
                          <b>Cyber Security</b>
                          <NavLink
                            to="training/digital-forensic-services"
                            className="nav-link"
                          >
                            Diploma in Cyber Security (DCS)
                          </NavLink>
                        </li>
                        {/* <li class="nav-item">
                                            <NavLink to="training/corporate_training" className="nav-link">
                                                Corporate Training
                                            </NavLink>
                                        </li> */}
                      </ul>
                    </li>

                    <li class="nav-item">
                      <NavLink to="workshop" className="nav-link">
                        Workshops
                      </NavLink>
                    </li>
                    <li class="nav-item">
                      <a href="#" className="nav-link">
                        IT Security Services
                      </a>
                      <ul class="navbar-nav nav mx-auto service_menu">
                        <li class="nav-item">
                          <b>VAPT Solutions</b>
                          <ul>
                            <li class="nav-item">
                              <NavLink
                                to="services/web-application-vapt-services"
                                className="nav-link"
                              >
                                Web VAPT
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                to="services/network-security-vapt-services"
                                className="nav-link"
                              >
                                Network VAPT
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                to="services/mobile-application-vapt-services"
                                className="nav-link"
                              >
                                Mobile Application VAPT
                              </NavLink>
                            </li>

                            <li class="nav-item">
                              <NavLink
                                to="services/cloud-vapt-services"
                                className="nav-link"
                              >
                                Cloud VAPT
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                to="services/ios-vapt-services"
                                className="nav-link"
                              >
                                IOS VAPT
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                to="services/iot-vapt-services"
                                className="nav-link"
                              >
                                IOT VAPT
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                        <li class="nav-item">
                          <b>Security Solutions</b>
                          <ul>
                            <li class="nav-item">
                              <NavLink
                                to="services/source-code-review-services"
                                className="nav-link"
                              >
                                Source Code Review
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                to="services/email-security-management-services"
                                className="nav-link"
                              >
                                E-mail Security Management
                              </NavLink>
                            </li>

                            <li class="nav-item">
                              <NavLink
                                to="services/data-recovery-services"
                                className="nav-link"
                              >
                                Data Recovery Services
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                to="services/digital-forensic-services"
                                className="nav-link"
                              >
                                Digital Forensic Services
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                to="services/iso-implementation-services"
                                className="nav-link"
                              >
                                ISO 270001 Implementation
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                        <li class="nav-item">
                          <b>IT Consulting</b>
                          <ul>
                            <li class="nav-item">
                              <NavLink
                                to="services/cyber-crime-investigation-services"
                                className="nav-link"
                              >
                                Cyber Crime Investigation
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                to="services/cyber_law_consulting_services"
                                className="nav-link"
                              >
                                Cyber Law Consulting
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                        <li class="nav-item">
                          <b>Security Operations Centre</b>
                          <ul>
                            <li class="nav-item">
                              <NavLink
                                to="services/soc-services"
                                className="nav-link"
                              >
                                SOC Services
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                to="services/cybersecurity-incident-response-services"
                                className="nav-link"
                              >
                                Incidence Response & Management
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                to="services/firewall-audit-services"
                                className="nav-link"
                              >
                                Firewall Auditing Services
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item">
                    <a href="#" className="nav-link">
                    Events
                      </a>
                      <ul class="navbar-nav nav" style={{width:'240px'}}>
                        <li class="nav-item">
                      <NavLink to="events" className="nav-link">
                      Events
                      </NavLink>
                    </li>
                    {
                      showup &&
                    <li class="nav-item">
                      <NavLink to="upevents" className="nav-link">
                     Upcoming Events
                      </NavLink>
                    </li>
                    }
                    </ul>
                    </li>
                    <li class="nav-item">
                      <NavLink to="career" className="nav-link">
                        Career
                      </NavLink>
                    </li>
                    <li class="nav-item">
                      <NavLink to="blogs" className="nav-link">
                        Blogs
                      </NavLink>
                    </li>
                    <li class="nav-item">
                      <NavLink to="contact-us" className="nav-link">
                        Contact Us
                      </NavLink>
                    </li>
                  </ul>
                </Navbar.Collapse>
              </Navbar>
            </Container>
          ))}
          <Offcanvas show={showMenu} onHide={handleClose} placement="start">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                <NavLink to="" onClick={handleClose} className="navbar-brand">
                  <img src={headerLogo} alt="logo" />
                </NavLink>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="mean-container">
              <Nav class="mean-nav">
                <ul class="navbar-nav nav lavalamp  menu flex-column">
                  <li class="nav-item">
                    <NavLink onClick={handleClose} to="">
                      Home
                    </NavLink>
                  </li>
                  <li class="nav-item">
                    <NavLink onClick={handleClose} to="about">
                      About Us
                    </NavLink>
                    <ul class="navbar-nav nav" style={{width:'240px'}}>
                        <li class="nav-item">
                      <NavLink to="team" className="nav-link">
                      Management team
                      </NavLink>
                    </li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Training</Accordion.Header>
                        <Accordion.Body>
                          <ul class="navbar-nav nav mx-auto">
                            <li class="nav-item">
                              <NavLink
                                onClick={handleClose}
                                to="training/certified-ethical-hacking-course"
                              >
                                Certified Information Security and Ethical
                                Hacker (CISEH)
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                onClick={handleClose}
                                to="training/certified-penetration-testing-expert-course"
                              >
                                Certified Penetration Testing Expert (CPTE)
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                onClick={handleClose}
                                to="training/certified-cyber-crime-investigator-course"
                              >
                                Certified Cyber Crime Expert and
                                Investigator(CCEI)
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                onClick={handleClose}
                                to="training/certified-soc-analyst-training"
                              >
                                Certified SOC Engineer (CSE)
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                onClick={handleClose}
                                to="training/digital-forensic-services"
                              >
                                Diploma in Cyber Security (DCS)
                              </NavLink>
                            </li>
                            {/* <li class="nav-item">
                                            <NavLink onClick={handleClose} to="training/corporate_training" >
                                                Corporate Training
                                            </NavLink>
                                        </li> */}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </li>

                  <li class="nav-item">
                    <NavLink onClick={handleClose} to="workshop">
                      Workshops
                    </NavLink>
                  </li>
                  <li class="nav-item">
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          IT Security Services
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul class="navbar-nav nav mx-auto">
                            <li class="nav-item">
                              <NavLink
                                onClick={handleClose}
                                to="services/network-security-vapt-services"
                              >
                                Network VAPT
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                onClick={handleClose}
                                to="services/web-application-vapt-services"
                              >
                                Web VAPT
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                onClick={handleClose}
                                to="services/mobile-application-vapt-services"
                              >
                                Mobile Application VAPT
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                onClick={handleClose}
                                to="services/cloud-vapt-services"
                              >
                                Cloud VAPT
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                onClick={handleClose}
                                to="services/iot-vapt-services"
                              >
                                IOT VAPT
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                onClick={handleClose}
                                to="services/ios-vapt-services"
                              >
                                IOS VAPT
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                onClick={handleClose}
                                to="services/soc-services"
                              >
                                SOC Services (Security Operations Center)
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                onClick={handleClose}
                                to="services/source-code-review-services"
                              >
                                Source Code Review Services
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                onClick={handleClose}
                                to="services/cyber-crime-investigation-services"
                              >
                                Cyber Crime Investigation Services
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                onClick={handleClose}
                                to="services/cyber_law_consulting_services"
                              >
                                Cyber Law Consulting Services
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                onClick={handleClose}
                                to="services/iso-implementation-services"
                              >
                                ISO 270001 Implementation Services
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                onClick={handleClose}
                                to="services/email-security-management-services"
                              >
                                E-mail Security Management Services
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                onClick={handleClose}
                                to="services/digital-forensic-services"
                              >
                                Digital Forensic Services
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                onClick={handleClose}
                                to="services/cybersecurity-incident-response-services"
                              >
                                Incidence Response & Management Services
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                onClick={handleClose}
                                to="services/data-recovery-services"
                              >
                                Data Recovery Services
                              </NavLink>
                            </li>
                            <li class="nav-item">
                              <NavLink
                                onClick={handleClose}
                                to="services/firewall-audit-services"
                              >
                                Firewall Auditing Services
                              </NavLink>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </li>
                  <li class="nav-item">
                    <NavLink onClick={handleClose} to="events">
                      Events
                    </NavLink>
                  </li>
                  <li class="nav-item">
                    <NavLink onClick={handleClose} to="career">
                      Career
                    </NavLink>
                  </li>
                  <li class="nav-item">
                    <NavLink onClick={handleClose} to="blogs">
                      Blogs
                    </NavLink>
                  </li>
                  <li class="nav-item">
                    <NavLink onClick={handleClose} to="contact-us">
                      Contact Us
                    </NavLink>
                  </li>
                </ul>
              </Nav>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </header>
    </>
  );
}

export default Header;
