export class ConfigServices {
 static BASE_URL = "https://backend.demmistotechnologies.com/";
  //  static BASE_URL = "http://localhost:5000/";
  static BASE_PATH_REGISTER = "register/";
  static BASE_PATH_INQUIRY = "inquiry/";
  static BASE_PATH_CAREER = "careers/";
  static BASE_PATH_WORKSHOP = "workshop/";
  static BASE_PATH_EVENTS = "events/";
  static BASE_PATH_UPEVENTS = "upevents/";
  static BASE_PATH_BLOG = "blogs/";
}
